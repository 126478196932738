/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Images
import Check from 'img/check.svg'

const Section = styled.section`
  max-width: 1700px;
`

const FlameWrapper = styled.div`
  top: 0;
  @media (min-width: 768px) {
    left: -100px;
  }
  @media (max-width: 1259px) {
    opacity: 0.15;
  }
  @media (max-width: 767px) {
    position: absolute;
    width: 300px;
    height: 300px;
  }
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  z-index: 2;
  @media (min-width: 768px) {
    left: -100px;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 55px;
      margin-bottom: 2rem;
    }
  }

  & ul {
    list-style: none;
    padding-left: 0;
    & li {
      display: flex;
      font-size: 18px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      @media (min-width: 576px) {
        align-items: center;
      }
      &:before {
        position: relative;
        top: 3px;
        content: url(${Check});
        margin-right: 15px;
      }
    }
  }
`

interface UspProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usp
}

const Partners: React.FC<UspProps> = ({ fields }) => (
  <Section className="mb-5 pb-lg-5 d-flex mx-auto position-relative">
    <FlameWrapper>
      <Image image={fields.image} alt="" className="position-absolute" />
    </FlameWrapper>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row justify-content-end align-items-center">
            <div className="col-md-7">
              <Content
                content={fields.description}
                className="position-relative py-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default Partners
